import { template as template_fa828ef9679a45cb8a1e38a773646522 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuShowMoreButton extends Component {
    static shouldRender(args) {
        return args.state.collapsedButtons.length && args.state.collapsed;
    }
    static{
        template_fa828ef9679a45cb8a1e38a773646522(`
    <DButton
      class="post-action-menu__show-more show-more-actions"
      ...attributes
      @action={{@buttonActions.showMoreActions}}
      @icon="ellipsis"
      @title="show_more"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
