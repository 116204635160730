import { template as template_8ce1add9eb9a4aa98b1d4c2ec7423b51 } from "@ember/template-compiler";
const FKText = template_8ce1add9eb9a4aa98b1d4c2ec7423b51(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
