import { template as template_f76ab7f4c0e9475bb6abdd426024d4db } from "@ember/template-compiler";
const FKControlMenuContainer = template_f76ab7f4c0e9475bb6abdd426024d4db(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
