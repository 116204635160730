import { template as template_4a1953c2dca14c4080d4ae489a01a201 } from "@ember/template-compiler";
const FKLabel = template_4a1953c2dca14c4080d4ae489a01a201(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
